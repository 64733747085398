<template>
  <div class="chart-container">
    <section class="chart-container__parent-chart">
      <div class="chart-container__child-chart">
        <canvas id="myCanvas" width="200" height="200"></canvas>
      </div>
      <h2 class="font-bold mb-5">{{ $t("heading.share_value") }}</h2>
      <div class="chart-container__child-chart">
        <canvas id="anotherCanvas" width="200" height="200"></canvas>
      </div>
      <h2 class="font-bold mb-5">{{ $t("heading.share_count") }}</h2>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Chart",
  data() {
    return {
      capitalValue: "",
      shareValue: "",
      capitalUnite: ""
    };
  },
  mounted() {
    this.createCanvase("myCanvas", this.capitalValue, this.capitalUnite);
    this.createCanvase("anotherCanvas", this.shareValue, "share");
  },
  methods: {
    createCanvase(el, maxValue, unit) {
      let canvas = document.getElementById(el); // to get the element
      let context = canvas.getContext("2d"); //to return drawing context on canvas
      let al = 0; // use it for Amount loaded
      let start = -4.72; //From where to start position of progress;
      let cw = context.canvas.width / 2; //to get x cordinate;
      let ch = context.canvas.height / 2; // to get y coordinate;
      let diff;
      let bar = setInterval(progressBar, 50);
      function progressBar() {
        diff = (al / 100) * Math.PI * 2;
        context.clearRect(0, 0, 400, 200);
        context.beginPath();
        context.arc(cw, ch, 90, 0, 2 * Math.PI, false);

        context.lineWidth = 8;
        context.fillStyle = "#FFF";
        context.strokeStyle = "#eee";
        context.fill();
        context.strokeStyle = "#eee";
        context.stroke();
        context.fillStyle = "#000";
        context.strokeStyle = "#ea2126";
        context.textAlign = "center";
        context.lineWidth = 9;
        context.font = "20pt Font Bold";
        context.fillStyle = "#404040";
        context.beginPath();
        context.arc(cw, ch, 90, start, diff + start, false);
        context.stroke();

        context.fillText(maxValue, cw + 2, ch - 6);
        context.fillText(unit, cw + 5, ch + 30);
        if (al >= 75) {
          clearTimeout(bar);
        }
        al++;
      }
    }
  },
  computed: {
    ...mapGetters(["settings", "globalSettings"])
  },
  watch: {
    globalSettings: {
      handler(value) {
        if (value) {
          this.capitalValue = this.settings("capital_value");
          this.shareValue = this.settings("share_value");
          this.capitalUnite = this.settings("capital_unite");
        }
      },
      immediate: true
    }
  }
};
</script>
